/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import Client, {
  CheckoutLineItemInput,
  CheckoutLineItemUpdateInput,
} from 'shopify-buy';

// Client
const client = Client.buildClient({
  apiVersion: process.env.GATSBY_STOREFRONT_API_VERSION || `2024-04`,
  domain:
    process.env.GATSBY_SHOPIFY_STORE_URL || `ernest-performance1.myshopify.com`,
  storefrontAccessToken:
    process.env.STOREFRONT_ACCESS_TOKEN || `d18fd01fcb573c927a4501a1aaccc10c`,
});

const defaultValues: any = {
  isOpen: false,
  loading: false,
  onOpen: () => {},
  onClose: () => {},
  addVariantToCart: (lineItemsToUpdate: CheckoutLineItemInput[]) => {},
  removeLineItem: (checkoutId: string, lineItemId: string) => {},
  updateLineItem: (
    checkoutId: string,
    lineItemsToUpdate: CheckoutLineItemUpdateInput[],
  ) => {},
  updateCheckoutNote: (checkoutId: string, note: string) => {},
  didJustAddToCart: false,
  client,
  checkout: { lineItems: [], note: `` },
};

export const StoreContext = React.createContext(defaultValues);

const isBrowser = typeof window !== `undefined`;
const localStorageKey = `shopify_checkout_id`;

export const StoreProvider = ({ children }: any) => {
  const [checkout, setCheckout] = useState(defaultValues.checkout);
  const [loading, setLoading] = useState(false);
  const [didJustAddToCart, setDidJustAddToCart] = useState(false);

  function setCheckoutItem(checkout: any) {
    if (isBrowser) {
      localStorage.setItem(localStorageKey, checkout.id);
    }

    setCheckout(checkout);
  }

  useEffect(() => {
    const initializeCheckout = async () => {
      const existingCheckoutID = isBrowser
        ? localStorage.getItem(localStorageKey)
        : null;

      if (existingCheckoutID && existingCheckoutID !== `null`) {
        try {
          const existingCheckout = await client.checkout.fetch(
            existingCheckoutID,
          );
          if (!existingCheckout.completedAt) {
            setCheckoutItem(existingCheckout);
            return;
          }
        } catch (e) {
          localStorage.setItem(localStorageKey, ``);
        }
      }

      const newCheckout = await client.checkout.create();
      setCheckoutItem(newCheckout);
    };

    initializeCheckout();
  }, []);

  async function addVariantToCart(lineItemsToUpdate: CheckoutLineItemInput[]) {
    setLoading(true);
    const checkoutId: any = checkout.id;

    return client.checkout
      .addLineItems(checkoutId, lineItemsToUpdate)
      .then((res) => {
        setCheckout(res);
        setLoading(false);
        setDidJustAddToCart(true);
        setTimeout(() => setDidJustAddToCart(false), 1000);
      });
  }

  async function removeLineItem(checkoutId: string, lineItemId: string) {
    setLoading(true);
    client.checkout.removeLineItems(checkoutId, [lineItemId]).then((res) => {
      setCheckout(res);
      setLoading(false);
    });
  }

  async function updateLineItem(
    checkoutId: string,
    lineItemId: string,
    quantity: number,
  ) {
    setLoading(true);

    const lineItemsToUpdate = [{ id: lineItemId, quantity }];

    client.checkout
      .updateLineItems(checkoutId, lineItemsToUpdate)
      .then((res) => {
        setCheckout(res);
        setLoading(false);
      });
  }

  async function updateCheckoutNote(checkoutId: string, note: string) {
    setLoading(true);
    client.checkout
      .updateAttributes(checkoutId, {
        note,
      })
      .then((res) => {
        setCheckout(res);
        setLoading(false);
      });
  }

  return (
    <StoreContext.Provider
      value={{
        ...defaultValues,
        addVariantToCart,
        removeLineItem,
        updateLineItem,
        updateCheckoutNote,
        checkout,
        loading,
        didJustAddToCart,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};
