exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-ambassadors-tsx": () => import("./../../../src/pages/ambassadors.tsx" /* webpackChunkName: "component---src-pages-ambassadors-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-custom-clubs-tsx": () => import("./../../../src/pages/custom-clubs.tsx" /* webpackChunkName: "component---src-pages-custom-clubs-tsx" */),
  "component---src-pages-gift-certs-tsx": () => import("./../../../src/pages/gift-certs.tsx" /* webpackChunkName: "component---src-pages-gift-certs-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-membership-tsx": () => import("./../../../src/pages/membership.tsx" /* webpackChunkName: "component---src-pages-membership-tsx" */),
  "component---src-pages-partners-tsx": () => import("./../../../src/pages/partners.tsx" /* webpackChunkName: "component---src-pages-partners-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */)
}

