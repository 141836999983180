// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
import React from 'react';
import { StoreProvider } from './src/context/ShopifyStoreContext';

export const wrapRootElement = ({ element }) => {
  return <StoreProvider>{element}</StoreProvider>;
};

export const onRenderBody = ({ setHeadComponents }) => {
  setHeadComponents([
    <link
      rel="preload"
      href="/fonts/Primary.ttf"
      as="font"
      type="font/ttf"
      crossOrigin="anonymous"
      key="interFont"
    />,
    <link
      rel="preload"
      href="/fonts/Secondary.ttf"
      as="font"
      type="font/ttf"
      crossOrigin="anonymous"
      key="interFont"
    />,
    <link
      rel="preload"
      href="/fonts/Tertiary.ttf"
      as="font"
      type="font/ttf"
      crossOrigin="anonymous"
      key="interFont"
    />,
  ]);
};
